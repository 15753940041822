// modules
/*
  https://github.com/reduxjs/redux-thunk/issues/333
  Avoid typescript issues when dispatching thunk actions
 */
import type {} from "redux-thunk/extend-redux";
import { RouteObject, useRoutes } from "react-router";
import("isomorphic-fetch");
import React, { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";
import isToday from "dayjs/plugin/isToday";

const locale = typeof navigator !== "undefined" ? navigator.language : undefined;

if (locale) {
  Intl.DateTimeFormat(locale, { hour: "numeric" }).resolvedOptions().hour12; // ?
  dayjs.locale(locale);
}

dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(isToday);

// components
import { getDefaultPrimary, useCreateTheme } from "#components/common/theme";

// locales
import i18n from "#shared/config/ui/i18n";

// types
import { PageProps } from "../types/types";

// routes
import AllRoutes from "../components/routing/routes";

// styles
import "../styles/main.scss";
import { ThemeProvider } from "@mui/material/styles";
import { useSelectorTyped } from "../utils";
import { importMessages } from "../helper";
import { selectLocale, setLocale } from "#features/locale/localeSlice";
import usePrevious from "#shared/utils/hooks/usePrevious";
import { locales } from "#shared/types";
import useGoogleTags from "../utils/useGoogleTags";
import useErrorHandler from "#components/common/ErrorHandler";
import { MediaQueryProvider } from "#shared/context/MediaQueryProvider";
import { ModalProvider } from "#shared/components/ui/Modal/ModalProvider";
import ToastProvider from "#components/common/Toast/ToastProvider";

export { Page };

i18n.setAvailableLocales([
  "en",
  "zh_CN",
  "fr",
  "de",
  // "id",
  "it",
  // "ja",
  // "ms",
  // "pl",
  // "pt",
  // "ru",
  "es",
  // "tr",
]);

function Page(pageProps: PageProps) {
  const { translations, wlaOrganisation } = pageProps;
  const locale = useSelectorTyped(selectLocale);
  const [messages, setMessages] = useState(translations);

  const theme = useCreateTheme({
    primary: getDefaultPrimary(wlaOrganisation?.primaryColor),
  });

  const previousLocale = usePrevious(locale);
  const dispatch = useDispatch();
  useGoogleTags();
  useErrorHandler();

  // if accept languages server side differ from language stored in local storage
  useEffect(() => {
    const localeInStorage = localStorage.getItem("locale");
    if (localeInStorage && localeInStorage !== locale) {
      dispatch(setLocale(localeInStorage as keyof typeof locales));
    }
  }, []);

  useEffect(() => {
    (async function loadMessages() {
      if (previousLocale && locale && previousLocale !== locale) {
        const messages = await i18n.getMessages(locale, await importMessages(locale));
        if (messages) {
          setMessages(messages);
          localStorage.setItem("locale", locale);
        }
      }
    })();
  }, [locale]);

  return (
    <ThemeProvider theme={theme}>
      <IntlProvider
        key={locale}
        messages={messages}
        locale={locale.slice(0, 2)}
        defaultLocale={i18n.getDefaultLocale()}
      >
        <React.Suspense>
          <ModalProvider>
            <MediaQueryProvider>
              <ToastProvider>{useRoutes(AllRoutes() as RouteObject[])}</ToastProvider>
            </MediaQueryProvider>
          </ModalProvider>
        </React.Suspense>
      </IntlProvider>
    </ThemeProvider>
  );
}
